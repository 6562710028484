const Vue = require('vue/dist/vue.js')
const VueRouter = require('vue-router')
const $ = require('jquery')
// const jQuery = require('jquery')
const VueBar = require('vuebar')
// const FancyBox = require('@fancyapps/fancybox')

Vue.use(VueRouter)
Vue.use(VueBar)

const filter_wildcard = 'alle'

const ProductListView = {
  template: '#vue-collection-results',
  props: {
    products: {
      type: Array,
      default: [],
    },
    intro: {
      type: Object,
    },
  },
}

const router = new VueRouter({
  routes: [
    {
      path: '/collectie/:gender/:category?/:brand?',
      name: 'collection',
      component: ProductListView,
    },
    {
      path: '/kids-collectie/:category?/:brand?',
      name: 'kids-collection',
      component: ProductListView,
    },
  ],
  mode: 'history',
})

const App = new Vue({
  router,
  el: '#vue-collection',
  data: {
    collectionLoaded: false,
    forceShowFilters: false,
    site: MNVR.site,
    products: [],
    brands: [],
    categories: [],
    categoryFilterActive: true,
    brandFilterActive: true,
    brandDescription: '',
    brandTitle: '',
    introObject: MNVR.intro || null,
  },
  mounted: function () {
    const args = {
      gender: this.gender,
      site: this.site,
    }

    $.post('/api/collection/', args, (response) => {
      // response = JSON.parse(response)
      this.products = response.products
      this.brands = response.brands
      this.categories = response.categories
      this.collectionLoaded = true
    })
  },
  updated: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.initProductPreview()
    })
  },
  methods: {
    initProductPreview: function () {
      window.initProductPreview()
    },
    compare: function (a, b) {
      if (a.slug < b.slug) {
        return -1
      }
      if (a.slug > b.slug) {
        return 1
      }
      return 0
    },
    updateIntro() {
      const intro = {}

      intro.title = (
        this.brandTitle +
        (this.activeCategory !== filter_wildcard
          ? ` ${this.activeCategory}`
          : '')
      ).trim()
      intro.desc = this.brandDescription || ''

      this.introObject = intro
    },
    getBrandPage() {
      if (this.activeBrand === filter_wildcard) {
        this.brandDescription = ''
        this.brandTitle = ''
        this.updateIntro()
      } else {
        $.get('/api/brandpage/', { slug: this.activeBrand }, (response) => {
          // response = JSON.parse(response)
          if (!response.error) {
            this.brandDescription = response.desc || ''
            this.brandTitle = response.title
          }
          this.updateIntro()
        })
      }
    },
  },
  computed: {
    gender() {
      return this.$route.params.gender
    },
    activeBrand: {
      get() {
        const value = this.$route.params.brand
        return value || filter_wildcard
      },
      set(value) {
        this.$router.push({
          name:
            this.site === 'de-ruyte-kids' ? 'kids-collection' : 'collection',
          params: {
            gender: this.gender,
            brand: value,
            category: this.activeCategory,
          },
        })
      },
    },
    activeCategory: {
      get() {
        const value = this.$route.params.category
        return value || filter_wildcard
      },
      set(value) {
        this.$router.push({
          name:
            this.site === 'de-ruyte-kids' ? 'kids-collection' : 'collection',
          params: {
            gender: this.gender,
            brand: this.activeBrand,
            category: value,
          },
        })
      },
    },
    filtered_products: function () {
      return this.products.filter((product) => {
        // check if product has current brand
        let hasBrand = true
        if (this.activeBrand !== filter_wildcard) {
          hasBrand = this.activeBrand === product.brand.slug
        }
        // check if product has current category
        let hasCategory = true
        if (this.activeCategory !== filter_wildcard) {
          hasCategory = this.activeCategory == product.category.slug
        }
        return hasBrand && hasCategory
      })
    },
    sorted_brands: function () {
      return this.brands.sort(this.compare)
    },
    sorted_categories: function () {
      return this.categories.sort(this.compare)
    },
  },
  watch: {
    activeBrand() {
      this.getBrandPage()
    },
    activeCategory() {
      this.getBrandPage()
    },
  },
})
