var $ = require('jquery')

// Modernizr tests.
// See: https://github.com/jnordberg/browsernizr
require('browsernizr/test/touchevents')
require('browsernizr/test/forms/placeholder')
require('browsernizr')

require('./polyfills.js')
require('./utils.js')

if (document.getElementById('vue-collection')) {
  require('./modules/vue-collection.js')
}

var Filter = require('./modules/filter.js')
var NinjaForm = require('./modules/ninjaform.js')

if (!window.jQuery) window.jQuery = window.$ = $
require('../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/transition.js')
require('../../bower_components/bootstrap-sass/assets/javascripts/bootstrap/collapse.js')

require('slick-carousel')
require('lightgallery')
var Masonry = require('masonry-layout')
var imagesloaded = require('imagesloaded')
var Instafeed = require('instafeed.js')
var Stickyfill = require('stickyfilljs')

var ticking,
  handlers,
  $siteheader = $('#site-header'),
  $brandslider = $('#brands-slider')

var elements = document.querySelectorAll('.collection__sidebar')
Stickyfill.add(elements)

function initialize() {
  ticking = false

  bindEvents()

  createForms()
  createSliders()
  createGrids()
  createFilters()
  createMaps()

  setTimeout(function () {
    initPage(
      'sessionStorage' in window && window.sessionStorage.getItem('init')
    )
  }, 500)

  var accounts = {
    'de-ruyte-kids': {
      userId: '10929282283',
      accessToken: '10929282283.15eea60.5250eceaf8f24d54984c7c532442e7ab',
    },
    'de-ruyte': {
      userId: '398285836',
      accessToken: '398285836.15eea60.ef11b22e34fd4ce7ab0334511289f242',
    },
  }

  var feed = new Instafeed({
    get: 'user',
    userId: accounts[MNVR.site].userId,
    //userId: '398285836', // '2160391827'
    // clientId: '15eea608f8fb41a78e122f32a1b67b39',
    accessToken: accounts[MNVR.site].accessToken,
    limit: 20,
    mock: true, // don't insert directly into DOM.
    // filter: function(item){
    //   return item.tags.indexOf('deruyteshoes') >= 0;
    // },
    success: function (result) {
      var template = $('#ig-template').html()
      var $items = $()
      var $con = $('#instafeed')
      var data = result.data
      var max = 3

      // console.debug(data);

      data.forEach(function (obj) {
        var $item = $(template)
        var text = obj.caption.text
        var tags = obj.tags

        // max items
        if (max > 0) {
          max--

          // - remove tags from caption
          // - create links
          tags.forEach(function (tag, i) {
            text = text.replace('#' + tag, '')
            tags[i] =
              '<a target="_blank" href="//instagram.com/explore/tags/' +
              tag +
              '">#' +
              tag +
              '</a>'
          })

          $item
            .find('[data-ig-image]')
            .attr('src', obj.images.low_resolution.url)
          $item.find('[data-ig-title]').text(text)
          $item.find('[data-ig-tags]').html(function () {
            if (tags.length) return tags.join(' ')
          })
          $items = $items.add($item)
        }
      })

      $con.append($items)
    },
  })
  feed.run()

  $('.brand-gallery').lightGallery({
    download: false,
  })
  $('.brands__link').on('click', function () {
    $(this).parent().find('.brand-gallery').find('a:first').trigger('click')
  })
}

function initPage(skipLoader) {
  var $items = $('[data-init]')
  var prevdelay = 0
  var $loader = $('#page-loader')
  var timer = skipLoader ? 100 : 1000

  // start the loader.
  if (skipLoader) {
    $loader.parent().remove()
  } else {
    $loader.addClass('start')
  }

  // after the loader:
  setTimeout(function () {
    $loader.addClass('fadeout')

    /*$items.each(function(){
      var $t = $(this);
      var delay = $t.data('init-delay') || 0;

      if (delay.toString().indexOf('+') === 0) {
        delay = prevdelay + Math.abs(delay);
      }

      setTimeout(function(){
        $t.addClass('init-done');
      }, delay);

      prevdelay = delay;
    });*/

    $('body.index .site-header__part').addClass('init')
    $('body.index .home-block').addClass('init')

    window.sessionStorage.setItem('init', '1')
  }, timer)
}

function createForms() {
  var cid =
    MNVR.site === 'de-ruyte-kids'
      ? mnvr_forms.contact_kids.id
      : mnvr_forms.contact.id
  var contactForm = new NinjaForm(cid)
  contactForm.parseIn('#frmContact')
}

function createSliders() {
  if ($brandslider.length) {
    // Something goes seriously wrong with the calculation of the slides their width.
    // Probably something to do with the overal surrounding flexbox.
    // To fix this, we explicitly set the width of the appropriate page-section.
    // $brandslider.closest('.page-section').each(function(){
    //   var w = $(this).innerWidth();
    //   $(this).width(w);
    // });

    $brandslider.slick({
      draggable: false,
      fade: true,
      arrows: false,
      infinite: false,
      keyboard: false,
    })

    var timer

    $('.brands__list__column')
      .on('mouseenter', 'a.has-image', function () {
        var postid = $(this).data('id')
        var index = $brandslider
          .find('.slider__item[data-id="' + postid + '"]')
          .index()
        clearTimeout(timer)
        timer = setTimeout(function () {
          $brandslider.slick('slickGoTo', index)
        }, 300)
      })
      .on('mouseleave', 'a.has-image', function () {
        clearTimeout(timer)
      })
  }
}

function createGrids() {
  if ($('#collection-grid').length) {
    imagesloaded($('#collection-grid').get(0), function () {
      new Masonry('#collection-grid', {
        itemSelector: '.collection-grid__item',
        columnWidth: 350,
        gutter: 20,
        fitWidth: true,
      })
    })
  }
}

function createFilters() {
  var stories_filter = new Filter($('.story'))
  stories_filter.setNav($('#stories_filter').find('button'))
}

function createMaps() {
  if ($('#gmaps').length) {
    var gmaps = require('./modules/gmap.js')
    gmaps.create({
      lat: 51.221351,
      lng: 4.285173,
      scrollwheel: true,
      // zoom: 14,
      markerIcon: MNVR.templateDir + '/assets/img/marker-derefter.png',
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#444444' }],
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry.stroke',
          stylers: [{ weight: '1.2' }],
        },
        {
          featureType: 'administrative.province',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.stroke',
          stylers: [{ weight: '1' }, { visibility: 'off' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [{ color: '#f2f2f2' }],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            { saturation: -100 },
            { lightness: 45 },
            { visibility: 'on' },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'transit.station',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#b4d2dc' }],
        },
      ],
    })
  }
}

/*
 * Main method for binding some events to the document or window.
 */
function bindEvents() {
  $(window).on('resize scroll', requestTick)

  /*$(document)
  .on('click', '.site-header--deruyte, .content--deruyte', function(e){
    $('html').addClass('open-dr').removeClass('open-drk');
  })
  .on('click', '.site-header--deruytekids, .content--deruytekids', function(e){
    $('html').addClass('open-drk').removeClass('open-dr');
  });*/

  handlers.onResize()
  handlers.onScroll()
}

function requestTick(e) {
  var func = 'on' + e.type.capitalize()
  // console.log(func.apply());
  if (!ticking && func in handlers) {
    window.requestAnimationFrame(handlers[func])
    ticking = true
  }
}

/*
 * Event Handlers object.
 */
handlers = {
  onResize: function () {
    // do stuff here

    ticking = false
  },

  onScroll: function () {
    // do stuff here

    $siteheader.toggleClass('shrink', window.scrollY > 100)

    ticking = false
  },
}

// @TODO: put in seperate file.
var outdatedBrowserRework = require('outdated-browser-rework')
outdatedBrowserRework({
  browserSupport: {
    Chrome: 37, // Includes Chrome for mobile devices
    IE: 10,
    Safari: 7,
    'Mobile Safari': 7,
    Firefox: 32,
  },
})

/* KICKSTART */
initialize()
